<template>
	<v-container class="main-login">
		<div
			class="main-login__bg"
			:style="{
				backgroundImage:
					'url(' + require('../assets/images/background.jpg') + ')',
			}"
		>
			<img
				class="main-login__bg-logo"
				:src="require('../assets/images/logo-confipetrol.png')"
				alt=""
			/>
		</div>
		<div class="main-login__form">
			<div class="d-flex flex-column w-100">
				<h1 class="form__title text-center">Identificarse</h1>
						<span class="form__caption text-center">Bienvenido</span>
				<v-alert
				class="w-100 text-center mt-5"
				color="red"
				dense
				outlined
				text
				type="error"
				v-text="error"
				v-if="error"
				></v-alert>
				<v-btn
				class="mt-5"
				color="warning"
				tabindex="3"
				@click="redirectLogin()"
				>
				Iniciar de sesión
				</v-btn>
			</div>
			<!-- <form-login /> -->
		</div>
	</v-container>
</template>

<script>
// import FormLogin from '../components/auth/FormLogin';
// ../assets/images/logo-confipetrol-dark.jpg
import { mapActions } from 'vuex';
import { urlApi } from '../store/api/config';
export default {
	name: 'Login', // [SOLO] Login a LoginComponent
	components: {
		// FormLogin,
	},
	data: () => ({
		error: null,
	}),
	mounted() {
		this.validateSession();
	},
	methods: {
		...mapActions('auth', ['setSession']),
		async validateSession() {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			if (urlParams.has('error')) {
				this.error = urlParams.get('error');
			} else if (urlParams.has('token')) {
				const { ok, message } = await this.setSession({
					email: '',
					token: urlParams.get('token'),
				});
				if (ok) {
					this.error = message;
					this.$router.push('/dashboard');
				} else {
					this.error = message;
				}
			}
		},
		redirectLogin() {
			location.href = `${urlApi}/auth/login`;
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/login.scss';
</style>
